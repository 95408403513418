
export const REGIONS_NETHERLANDS = [
  "Alle provincies",
  "Drenthe",
  "Friesland",
  "Flevoland",
  "Gelderland",
  "Groningen",
  "Limburg",
  "Noord-Brabant",
  "Noord-Holland",
  "Overijssel",
  "Utrecht",
  "Zeeland",
  "Zuid-Holland"
];

export interface Festival {
  name: string;
  dates: Array<string>;
  location: {
    country: string;
    city: string;
    venue: string;
  };
  categories: Array<string>;
  thumbnailURL: string;
}

const FESTIVALS: Array<Festival> = [
  {
    name: "909 Festival",
    dates: [
      "2022-05-05"
    ],
    location: {
      country: "The Netherlands",
      city: "Amstelveen",
      venue: "Het Amsterdamse Bos"
    },
    categories: ["Techno"],
    thumbnailURL: "https://909.nl/wp-content/uploads/2021/02/909-LOGO-WEBSITE-2022.png"
  },
  {
    name: "DGTL",
    dates: [
      "2022-04-15"
    ],
    location: {
      country: "The Netherlands",
      city: "Amsterdam",
      venue: "Houthavens"
    },
    categories: ["Techno"],
    thumbnailURL: "",
  }
];

export function getFestivals(from: string, country: string, region: string, category: string): Array<Festival> {
  return FESTIVALS.filter( f => (
      (f.dates[0] >= from) &&
      (f.categories.includes(region) || region == "Alle provincies") &&
      (f.categories.includes(category) || category == "Alle muzieksoorten")
    )
  );
}